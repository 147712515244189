/* MAIN COLOR */

$color-green: #51bba0;
$color-green-light: #00d49e;
$color-green-lighter: #e0e9c8;
$color-green-lightest: #f4f7ec;
$color-green-dark: #259c7c;
$color-green-darker: #245749;

/* BLACK, WHITE, AND GREY */

$color-white: #fff;

$color-grey: #e0e0e0;
$color-grey-light: #eeeeee;
$color-grey-lighter: #f5f5f5;
$color-grey-lightest: #fafafa;
$color-grey-dark: #bdbdbd;
$color-grey-darker: #9e9e9e;
$color-grey-darkest: #757575;

$color-black: #424242;
$color-black-light: #616161;
$color-black-dark: #212121;

/* SECONDARY COLOR */

$color-orange: #ffb300;
$color-orange-light: #fbd074;
$color-orange-dark: #fc902c;

/* ADDITIONAL SOLID COLORS */

$color-blue: #60b4c2;
$color-blue-light: #75dbec;
$color-blue-dark: #46838e;
$color-blue-darkest: #286470;

$color-magenta: #cc2b5e;
$color-purple: #753a88;

$color-red: #ef473a;
$color-red-dark: #cb2d3e;

/* FOCUS AND CONTEXT COLORS */

$color-focus-background: $color-white;
$color-focus-text: $color-black-dark;
$color-focus-border: $color-grey;

$color-context-background: lighten($color-green, 44%);
$color-context-text: $color-black;

@mixin color-focus {
  background-color: $color-focus-background;
  color: $color-focus-text;
  @content;
}

@mixin color-context {
  background-color: $color-context-background;
  color: $color-context-text;
  @content;
}

/* GRADIENTS */

$color-gradient-green: linear-gradient(29deg, $color-green 17%, $color-green-light 87%);
$color-gradient-orange: linear-gradient(29deg, $color-orange 17%, $color-orange-dark 87%);
$color-gradient-blue: linear-gradient(29deg, $color-blue 17%, $color-blue-light 87%);
$color-gradient-blue-dark: linear-gradient(29deg, $color-blue-darkest 17%, $color-blue-dark 87%);
$color-gradient-red: linear-gradient(29deg, $color-red 17%, $color-red-dark 87%);
$color-gradient-purple: linear-gradient(29deg, $color-magenta 17%, $color-purple 87%);
$color-gradient-black: linear-gradient(29deg, $color-black 17%, $color-black-dark 87%);
